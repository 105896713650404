<template>
    <interior-page-content-container
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="false"
      :loading="loading"
      navSelection="admin"
    >

      <template #title>
        Checklist Config
      </template>

      <template #content>
        <div class="flex flex-col self-center h-full py-2">

          <div class="w-full text-2xl font-medium text-left text-content">
            Services</div>

          <admin-checklist-component
            v-for="(checklist, index) in checklists" :key="`index-${index}-service`"
            v-show="checklist.type === 'Service'"
            @click="openChecklist(checklist.id)"
            :name="checklist.name"
            :type="checklist.type"
            :itemsCount="checklist.items.length"
          />
          <div
            v-if="serviceLists.length === 0"
            class="pt-4 w-72 md:w-80 lg:w-96 text-content"
          >
            No Service Checklists added yet
          </div>

          <div class="w-full mt-5 text-2xl font-medium text-left text-content">Safety</div>
          <admin-checklist-component
            v-for="(checklist, index) in checklists" :key="`index-${index}-safety`"
            v-show="checklist.type === 'Safety'"
            @click="openChecklist(checklist.id)"
            :name="checklist.name"
            :type="checklist.type"
            :itemsCount="checklist.items.length"
          />

          <div
            v-if="safetyLists.length === 0"
            class="pt-4 w-72 md:w-80 lg:w-96 text-content"
          >
            No Safety Checklists added yet
          </div>

          <div class="flex flex-col items-center justify-end flex-grow m-5">
            <button-large
              @click="$router.push({name: 'AdminChecklistCreate'})"
              color="primary"
              class="my-5"
            >
              New Checklist
            </button-large>
          </div>

        </div>

      </template>

    </interior-page-content-container>
</template>

<script>

import {
  ADMIN_CHECKLISTS_GET,
} from '@/store/actions';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import AdminChecklistComponent from '@/components/projects/checklists/AdminChecklistComponent.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'AdminChecklists',
  components: {
    InteriorPageContentContainer,
    AdminChecklistComponent,
    ButtonLarge,
  },
  data() {
    return {
      loading: true,
      selected: 1,
      search: '',
      checklists: [],
      checklistIndexToDelete: null,
    };
  },
  computed: {
    serviceLists() { return this.checklists.filter((checklist) => checklist.type === 'Service'); },
    safetyLists() { return this.checklists.filter((checklist) => checklist.type === 'Safety'); },
  },
  created() {
    this.$store.dispatch(ADMIN_CHECKLISTS_GET)
      .then((checklists) => {
        this.checklists = checklists;
        this.checklists.sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
      })
      .finally(() => { this.loading = false; });
  },
  methods: {
    backNav() {
      this.$router.push({
        name: 'Dashboard',
      });
    },
    openChecklist(id) {
      this.$router.push({
        name: 'AdminChecklist',
        params: {
          checklist_id: id,
        },
      });
    },
  },
};
</script>

<style>

</style>
