<template>
  <div
    @click="$emit('click')"
    class="`
      flex flex-row border flex-grow-0 flex-shrink-0 border-contrast
      rounded-md cursor-pointer w-72 mt-4 md:w-80 lg:w-96 bg-backfill
      shadow-lg
      hover:shadow-md active:shadow-none
      admin-checklist-component
    `"
  >
    <div class="flex flex-col items-start justify-between w-full pt-2 pb-1 pl-2">
        <p class="font-bold text-left text-secondary">{{name}}</p>
        <p class="mt-1 text-content">
          {{`${itemsCount} ${(itemsCount === 1 ? 'item' : 'items')}`}}
        </p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ProjectComponent',
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    itemsCount: {
      type: Number,
      required: true,
    },
    image: {
      type: String,
      required: false,
    },
  },
};
</script>
